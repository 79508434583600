<template>

  <div v-if="value">
    <div class="d-flex" v-if="utils.sameDay(value.start, value.end)">
      <div>{{utils.dateToNiceDay(value.start)}}</div>
      <div class="ml-2">{{utils.dateToNiceTime(value.start)}} until {{utils.dateToNiceTime(value.end)}}</div>
    </div>
    <div v-else>
      {{utils.dateToNiceString(value.start)}} to {{utils.dateToNiceString(value.end)}}
    </div>
  </div>
  
</template>

<script>
  import Utils from '@/services/Utils.js'
  export default {
    props: ['value'],

    computed: {
      utils: function () {
        return Utils;
      },
    },
  }
</script>

